<template>
  <div class="church-communications template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcGideonCardCommunications }}</h1>
      <BackJump class="mt-3" @reload_host="handleChurchChange()" :obj="backJumpObj" :vis="true"
        :i18n="translations.components"></BackJump>
    </page-header>
    <page-body class="mx-lg pt-3 mt-3 page">
      <section class="section-1">
        <div class="d-flex section-header">
          <h2>{{ translations.tcAddresses }}</h2>
          <b-button v-if="iCanSeeAny(secured_address_add_controls)" variant="primary" @click="handleAddAddressClick">{{
            translations.tcAddAddress
          }}</b-button>
        </div>
        <div class="d-flex flex-wrap" v-if='translations.components'>
          <ServiceCard v-for="(address, index) in addresses" :i18n="translations.components" :key="index" :obj="address"
            :secure_edit_keys="{ ...secured_address_edit_controls, ...secured_address_add_controls }"
            :secure_delete_keys="{ ...secured_address_delete_controls, ...secured_address_add_controls }"
            @edit_requested="handleEditRequest(address)" @delete_approved="handleDeleteRequest(address)"></ServiceCard>
        </div>
      </section>
      <section class="section-2">
        <div class="d-flex section-header">
          <h2>{{ translations.tcCommunications }}</h2>
          <b-button v-if="iCanSeeAny(secured_phone_fax_add_controls)" variant="primary" class="mr-2"
            @click="handleAddPhoneFaxClick">{{ translations.tcAddPhoneOrFax }}</b-button>
          <b-button variant="primary" @click="handleAddEmailClick" v-if="iCanSeeAny(secured_email_add_controls)">{{
            translations.tcAddEmail
          }}</b-button>
        </div>
        <div class="d-flex flex-wrap" v-if='translations.components'>
          <ServiceCard v-for="(phone, index) in contactInfo.fax_phone_info" :i18n="translations.components" :key="index"
            :obj="phone" :secure_edit_keys="{ ...secured_phone_fax_edit_controls, ...secured_phone_fax_add_controls }"
            :secure_delete_keys="{ ...secured_phone_fax_delete_controls, ...secured_phone_fax_add_controls }"
            @edit_requested="handlePhoneFaxEditRequest(phone)" @delete_approved="handlePhoneDeleteRequest(phone)">
          </ServiceCard>
          <ServiceCard v-for="(email, index) in contactInfo.email_info" :i18n="translations.components"
            :key="index + 50" :obj="email"
            :secure_edit_keys="{ ...secured_email_edit_controls, ...secured_email_add_controls }"
            :secure_delete_keys="{ ...secured_email_delete_controls, ...secured_email_add_controls }"
            @edit_requested="handleEmailEditRequest(email)" @delete_approved="handleEmailDeleteRequest(email)">
          </ServiceCard>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpGideonCards.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_communication',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_email_edit_controls: {
        edit_gideoncard_facility_email_button: '3298a90d-b6ff-460c-87cf-1104b4cf4387',
      },
      secured_email_delete_controls: {
        delete_gideoncard_facility_email_button: '31a6cc2f-9b0a-4c26-91b6-832efa44b52b',
      },
      secured_email_add_controls: {
        add_gideoncard_facility_email_button: '6fdbe111-3760-441c-aac5-45cf27c3b663',
      },
      secured_phone_fax_add_controls: {
        add_gideoncard_facility_phone_fax_button: '2e2eeeb9-f818-47f2-b28f-7d851465b514',
      },
      secured_phone_fax_edit_controls: {
        edit_gideoncard_facility_phone_fax_button: '46f3dbf1-1959-4b77-914f-7f30d738b301',
      },
      secured_phone_fax_delete_controls: {
        delete_gideoncard_facility_phone_fax_button: '4d89b5d8-2625-48cb-8d6a-98fe7b2a4995',
      },
      secured_address_add_controls: {
        add_gideoncard_facility_address_button: 'fff378a4-9927-4ad6-96fd-71ef8dc4193a',
      },
      secured_address_edit_controls: {
        edit_gideoncard_facility_address_button: '058018ee-da28-45b3-9038-01cbee43a664',
      },
      secured_address_delete_controls: {
        delete_gideoncard_facility_address_button: '91fe442a-8b74-4662-87cc-18a939aed524',
      },
      backJumpObj: {
        link: '#',
        location: '',
      },
    }
  },
  methods: {
    ...mapActions({
      fetchAddresses: 'card/getFacilityAddresses',
      getLocationCommunications: 'scriptureDistribution/getLocationCommunications',
      loadContactInfo: 'card/getFacilityContactInfo',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedAddress: 'user/setSelectedAddress',
      setSelectedEmail: 'user/setSelectedEmail',
      setSelectedPhone: 'user/setSelectedPhone',
      upsertAddress: 'card/upsertAddress',
      upsertEmail: 'card/upsertEmail',
      upsertPhone: 'card/upsertPhone'
    }),
    async handleAddAddressClick() {
      await this.setSelectedAddress(this.emptyAddress)
      this.$router.push({ name: 'card_facility_edit_address' })
    },
    async handleEditRequest(address) {
      await this.setSelectedAddress(address)
      this.$router.push({ name: 'card_facility_edit_address' })
    },
    async handleAddPhoneFaxClick() {
      await this.setSelectedPhone(this.emptyPhoneFax)
      this.$router.push('/programs/gc/card_facility_edit_phone')
    },
    async handlePhoneFaxEditRequest(phone) {
      await this.setSelectedPhone(phone)
      this.$router.push('/programs/gc/card_facility_edit_phone')
    },
    async handleChurchChange() {
      await this.setSelectedFacilityKey(this.userSelectedFacilityKey)
      this.pageLoad()
    },
    async handleEmailEditRequest(email) {
      await this.setSelectedEmail(email)
      this.$router.push({ name: 'card_facility_edit_email' })
    },
    async handleAddEmailClick() {
      await this.setSelectedEmail(this.emptyEmail)
      this.$router.push({ name: 'card_facility_edit_email' })
    },
    async handleDeleteRequest(address) {
      let data = {
        adr_key: address.adr_key,
        axe_key: address.axe_key,
        cnt_key: address.cnt_key,
        city: address.city,
        state: address.state,
        state_key: address.state_key,
        address_line_1: address.address_line1,
        address_line_2: address.address_line2,
        address_line_3: address.address_line3,
        postal_code: address.postal_code,
        member_key: this.userId,
        org_key: this.userSelectedFacilityKey,
        delete: true,
      }
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertAddress(data)
        this.setLoadingStatus(false)
        if (response != '') {
          await this.pageLoad()
          this.$swal({
            icon: 'success',
            confirmButtonText: this.translations.tcOk,
            text: this.translations.tcDeleteAddressSuccess,
          })
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcDeleteAddressFailure,
        })
      }
    },
    async handleEmailDeleteRequest(email) {
      let data = {
        eml_key: email.eml_key,
        emt_key: email.emt_key,
        primary: email.primary_email_flag == 1,
        email: email.email_address,
        member_key: this.userId,
        org_key: this.userSelectedFacilityKey,
        delete: true,
      }
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertEmail(data)
        this.setLoadingStatus(false)
        if (response != '') {
          await this.pageLoad()
          this.$swal({
            icon: 'success',
            confirmButtonText: this.translations.tcOk,
            text: this.translations.tcDeleteEmailSuccess,
          })
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcDeleteEmailFailure,
        })
      }
    },
    async handlePhoneDeleteRequest(phone) {
      let data = {
        phone_key: phone.phn_key,
        phone_axe_key: phone.pxe_key,
        pht_key: phone.pht_key,
        primary: phone.primary_phone_flag == 1,
        phone: phone.phone_number,
        extension: phone.extension,
        member_key: this.userId,
        org_key: this.userSelectedFacilityKey,
        delete: true,
      }
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertPhone(data)
        this.setLoadingStatus(false)
        if (response != '') {
          await this.pageLoad()
          this.$swal({
            icon: 'success',
            confirmButtonText: this.translations.tcOk,
            text: this.translations.tcDeletePhoneSuccess,
          })
        }
      } catch (error) {
        console.error(error)
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcDeletePhoneFailure,
        })
      }
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.fetchAddresses(this.userSelectedFacilityKey),
        this.loadContactInfo(this.userSelectedFacilityKey),
        await this.getLocationCommunications(this.userSelectedFacilityKey),
      ]).then(() => {
        this.backJumpObj.location = this.profileSummary.org_name
        this.backJumpObj.link = `/programs/gc/profile/summary/card_profile`
        this.setLoadingStatus(false)
      })
    },
  },
  computed: {
    ...mapGetters({
      addresses: 'card/getAddresses',
      contactInfo: 'card/getContactInfo',
      emptyAddress: 'churchMinistry/empty_address',
      emptyEmail: 'churchMinistry/empty_email',
      emptyPhoneFax: 'churchMinistry/empty_phone_fax',
      iCanSeeAny: 'user/iCanSeeAny',
      prefCulture: 'user/userPreferredCulture',
      profileSummary: 'card/getFacilitySummary',
      userId: 'user/userId',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey'
    }),
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump-gideon-cards', 'quick-jump-gideon-cards', 'service-card'),
      this.getComponentTranslations(
        'common.address-types',
        'common.attire',
        'common.church-service-type',
        'common.days',
        'common.email-types',
        'common.fax-types',
        'common.months-abbreviations',
        'common.phone-types',
      ),
      this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)
    })
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceCard: ServiceCard,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-communications {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  .section-header {
    margin-bottom: 45px;
    align-items: center;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    h2 {
      font-size: 42px;
      font-family: $DIN;
      line-height: 44px;
      letter-spacing: 1px;
      color: #000;
      margin-right: 30px;
      margin-bottom: 0;
      text-transform: uppercase;

      @include breakpoint(sm) {
        margin-bottom: 35px;
      }
    }

    .btn {
      margin-right: 10px;
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .section-1,
  .section-2 {
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-1 {
    margin-bottom: 75px;
    margin-top: 30px;

    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }

  .section-2 {
    margin-bottom: 20px;
  }
}
</style>
